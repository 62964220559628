// form
.ant-input-affix-wrapper {
  border-radius: 5px !important;
}

.ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 5px;
}

.ant-form-item .ant-select {
  min-width: 200px;
}

.ant-btn {
  word-spacing: -2.5px;
}

.ant-select-selection-search {
  display: flex;
  align-items: center;
}

// table
.table .ant-table-thead tr th {
  background: $solitude !important;
  color: $primary !important;
  font-size: 16px;
  font-weight: bold;
}

.table .ant-table {
  td {
    padding: 12px 8px !important;
  }
}

table.table {
  thead {
    background: #e1edff;
    th {
      color: #008faa;
    }
  }
  td {
    padding: 0.75rem !important;
  }
}

.table th,
.table td {
  vertical-align: middle;
  border-top: none !important;
  color: #000000;
}

body {
  .ant-form-item-label > label {
    color: #000000;
    font-size: 15px;
    font-weight: 700;
  }
  .ant-tabs-content {
    padding-bottom: 50px;
  }
  .ant-tabs-top > .ant-tabs-nav::before {
    border-bottom: none;
  }
  .ant-tabs-tab-btn {
    font-size: 24px;
    font-weight: bold !important;
    color: $secondline;
  }
  .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #000000;
  }
  .ant-tabs-ink-bar {
    display: none;
  }
  .ant-tabs-tab {
    margin-right: 15px;
    padding: 0;
    line-height: 1.2;
  }
  .ant-tabs-tab + .ant-tabs-tab {
    border-left: 2px solid #000000;
    padding-left: 15px;
    margin-left: 0px;
    margin-right: 15px;
  }
  .input-text,
  .ant-input {
    width: 100%;
    padding: 10px;
    border-radius: 5px;
  }
  .input-text.ant-picker.ant-picker-disabled {
    background: white;
    input[disabled] {
      color: black;
    }
  }
}

.ant-select-selector {
  height: auto !important;
  border-radius: 5px !important;
  padding: 6px 10px !important;
}

// radio
.radioDay {
  .ant-radio-button-wrapper {
    border-radius: 50%;
    margin-right: 10px !important;
    min-width: 40px;
    height: 40px;
    padding: 0;
    text-align: center;
    border-left-width: thin;
    color: #999999;
    font-size: 17px;
    font-weight: bold;
  }
  .ant-radio-button-wrapper:last-child,
  .ant-radio-button-wrapper:first-child {
    border-radius: 50%;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    width: 0;
    background-color: none;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):active,
  .ant-radio-button-wrapper:hover,
  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    background: $irisBlue !important;
    color: #ffffff !important;
    border-color: $irisBlue !important;
  }
}

// checkbox day
.checkboxDay {
  .ant-checkbox-inner {
    display: none;
  }
  &.ant-checkbox-group {
    display: flex;
    flex-wrap: wrap;
  }
  .ant-checkbox-wrapper {
    border-radius: 50%;
    margin-right: 10px !important;
    min-width: 40px;
    padding: 0;
    text-align: center;
    color: #999999;
    font-size: 17px;
    font-weight: bold;
    border: 1px solid #d9d9d9;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ant-checkbox-wrapper:last-child,
  .ant-checkbox-wrapper:first-child {
    border-radius: 50%;
  }
  .ant-checkbox-wrapper:not(:first-child)::before {
    width: 0;
    background-color: none;
  }
  .ant-btn:hover,
  .ant-btn:focus,
  .ant-btn:active,
  .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled):active,
  .ant-checkbox-wrapper:hover,
  .ant-checkbox-wrapper-checked:not(.ant-checkbox-wrapper-disabled) {
    background: $irisBlue !important;
    color: #ffffff !important;
    border-color: $irisBlue !important;
  }
}

.ant-tooltip-inner {
  background-color: #fff !important;
  color: #000 !important;
  font-weight: 550;
}

.ant-tooltip-arrow-content {
  background-color: #fff !important;
}

.modal-full-width {
  display: flex !important;
  align-items: center;
  justify-content: center;
  .modal-dialog {
    width: 98vw !important;
    height: 98vh;
    padding: 0;
    margin: 0 !important;
    max-width: 98vw !important;
    min-height: 98vh;
    max-height: 98vh;
  }
  .modal-content {
    height: 100%;
    border: 0;
  }
}

.table {
  margin-bottom: unset !important;
  min-width: 1000px;
}

.customTooltip {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
  > div {
    height: fit-content;
    width: 100%;
  }
}

.customTooltipMemo {
  display: block;
  height: 100%;
  min-height: 10px;
  > div {
    height: fit-content;
    width: 100%;

    > div {
      height: 100%;
    }
  }
}

.react-contextmenu-wrapper {
  height: 100%;
}

.disable-context-menu {
  .react-contextmenu-wrapper {
    height: 100% !important;
  }
}

.react-contextmenu {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
  color: #373a3c;
  font-size: 16px;
  margin: 2px 0 0;
  min-width: 160px;
  outline: none;
  opacity: 0;
  padding: 5px 0;
  pointer-events: none;
  text-align: left;
  transition: opacity 250ms ease !important;
  z-index: 999;
}

.react-contextmenu-item {
  background: 0 0;
  border: 0;
  color: #373a3c;
  cursor: pointer;
  font-weight: 400;
  line-height: 1.5;
  padding: 3px 20px;
  text-align: inherit;
  white-space: nowrap;
}

.table tr td:last-child {
  text-align: center;
  max-width: 200px;
}

.no-center tr td:last-child {
  text-align: left !important;
}

.rbc-events-container div.rbc-event.rbc-selected {
  border: 2px solid #000 !important;
}

.ant-form-item-required {
  display: inline-flex;
  flex-direction: row-reverse;
  &::before {
    margin-left: 4px;
  }
}

.ant-notification {
  z-index: 9999 !important;
  .ant-notification-notice {
    z-index: 9999 !important;
  }
}

.ant-pagination {
  margin-top: 30px !important;
  width: fit-content;
  background: $white;
  border: 1px solid $gray-light;
  border-radius: 5px;
  margin-left: auto !important;
  font-size: 12px !important;
  > li {
    > a:not([disabled]) {
      color: #828282;
    }
  }
  .ant-pagination-item {
    border: 0;
    border-right: 1px solid $gray-light;
    border-radius: 0;
    &:hover {
      border-color: $gray-light;
    }
  }
  .ant-pagination-item-active {
    background: $torea;
    > a {
      color: $white !important;
    }
    &:hover {
      border-color: $torea;
      > a {
        color: $white;
      }
    }
  }
  .ant-pagination-item,
  .ant-pagination-prev,
  .ant-pagination-jump-prev,
  .ant-pagination-jump-next {
    margin: 0;
  }
  .ant-pagination-prev {
    border-right: 1px solid $gray-light;
    border-radius: 0;
  }
  .ant-pagination-prev,
  .ant-pagination-next {
    width: 49px;
    height: 30px;
  }
  @media (max-width: 575px) {
    .ant-pagination-prev,
    .ant-pagination-next {
      width: fit-content;
    }
  }
}

.white-space-pre {
  white-space: pre-line;
}

.btn-back {
  // margin: 20px;
}

.btn-disabled {
  cursor: no-drop !important;
}

table th {
  white-space: nowrap;
}

.disabled {
  opacity: 0.5;
  cursor: no-drop !important;
}

.rbc-allday-cell {
  display: none;
}

.rbc-current-time-indicator {
  z-index: 1 !important;
  // background-color: #577f28;
  background-color: red !important;
}

.rbc-time-view {
  border-color: $solitude !important;
  .rbc-header {
    border-bottom: none;
  }
}

.rbc-time-header {
  min-height: 21px !important;
  & .rbc-time-header-content {
    background-color: #fff;
    .rbc-row {
      min-height: 21px !important;
    }
    .rbc-row.rbc-row-resource {
      border-bottom: none !important;
      height: 100%;
    }
  }
}

.rbc-time-content {
  border-top: 1px solid #cac0c0 !important;
  overflow-y: auto;
  max-height: calc(100vh - 218px);
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    position: absolute;
  }
}

.memoCount-1 {
  .rbc-time-content {
    max-height: calc(100vh - 225px);
  }
}

.memoCount-2 {
  .rbc-time-content {
    max-height: calc(100vh - 260px);
  }
}

.memoCount-3 {
  .rbc-time-content {
    max-height: calc(100vh - 285px);
  }
}

.memoCount-4 {
  .rbc-time-content {
    max-height: calc(100vh - 305px);
  }
}

.memoCount-5 {
  .rbc-time-content {
    max-height: calc(100vh - 325px);
  }
}

.memoCount-6 {
  .rbc-time-content {
    max-height: calc(100vh - 345px);
  }
}

.memoCount-7 {
  .rbc-time-content {
    max-height: calc(100vh - 365px);
  }
}

.memoCount-8 {
  .rbc-time-content {
    max-height: calc(100vh - 385px);
  }
}

.memoCount-9 {
  .rbc-time-content {
    max-height: calc(100vh - 405px);
  }
}

.memoCount-10 {
  .rbc-time-content {
    max-height: calc(100vh - 425px);
  }
}

.rbc-time-content > * + * > * {
  border-left: none !important;
}

.rbc-timeslot-group {
  border-left: 1px solid rgba(133, 133, 133, 0.2) !important;
  border-bottom: 1px solid rgba(133, 133, 133, 0.2) !important;
  min-height: 22px !important;
  max-height: 22px !important;
  height: 22px !important;
}

.rbc-day-slot {
  .rbc-timeslot-group {
    border: 0 !important;
    &:nth-child(4n) {
      &::after {
        content: '';
        border-bottom: 1px solid !important;
        z-index: 0;
      }
    }
  }
  .rbc-time-slot {
    border-left: 1px solid hsla(0, 0%, 52.2%, 0.4);
    border-bottom: 1px solid hsla(0, 0%, 52.2%, 0.4);
  }
  .calendarDay .rbc-time-slot {
    // min-width: 61px !important;
  }
  .calendarWeek .rbc-time-slot {
    // min-width: 140px !important;
  }
}

.calendarDay {
  .rbc-time-gutter {
    .rbc-time-slot {
      height: 16px !important;
    }
  }
}

.rbc-time-gutter {
  border-top: 1px solid #cac0c0 !important;
  &:nth-child(1) {
    border-top: 0 !important;
  }
  background: $solitude !important;
  .rbc-timeslot-group {
    position: relative;
    border-bottom: 0 !important;
    border-left: none !important;
    &:nth-child(4n + 1) {
      .rbc-label {
        background: #f3f3f3;
      }
    }
    &:nth-child(4n + 2) {
      .rbc-label {
        background: #f3f3f3;
      }
    }
    &:nth-child(4n) {
      border-bottom: 1px solid #000 !important;
      .rbc-label {
        border-bottom: none !important;
        // height: 41px !important;
        height: 19.8px !important;
      }
    }
    &:nth-child(4n + 2)::after {
      content: '';
      position: absolute;
      bottom: -15px;
      left: 25%;
      transform: translate(-50%, 0%);
      color: $primary;
      font-weight: bold;
      font-size: 24px;
    }
    &:nth-child(2)::after {
      content: '8';
      z-index: 999;
    }
    &:nth-child(6)::after {
      content: '9';
      z-index: 999;
    }
    &:nth-child(10)::after {
      content: '10';
      z-index: 999;
    }
    &:nth-child(14)::after {
      content: '11';
      z-index: 999;
    }
    &:nth-child(18)::after {
      content: '12';
      z-index: 999;
    }
    &:nth-child(22)::after {
      content: '13';
      z-index: 999;
    }
    &:nth-child(26)::after {
      content: '14';
      z-index: 999;
    }
    &:nth-child(30)::after {
      content: '15';
      z-index: 999;
    }
    &:nth-child(34)::after {
      content: '16';
      z-index: 999;
    }
    &:nth-child(38)::after {
      content: '17';
      z-index: 999;
    }
    &:nth-child(42)::after {
      content: '18';
      z-index: 999;
    }
    &:nth-child(46)::after {
      content: '19';
      z-index: 999;
    }
    &:nth-child(50)::after {
      content: '20';
      z-index: 999;
    }
    &:nth-child(54)::after {
      content: '21';
      z-index: 999;
    }
    &:nth-child(58)::after {
      content: '22';
      z-index: 999;
    }
    &:nth-child(62)::after {
      content: '23';
      z-index: 999;
    }
  }
}

.dayLate {
  .rbc-time-gutter {
    border-right: 1px solid $hawkesBlue !important;
    border-top: 1px solid rgba(133, 133, 133, 0.1) !important;
    background: $solitude !important;
    .rbc-timeslot-group {
      position: relative;
      border-bottom: 0 !important;
      border-left: none !important;
      &:nth-child(4n) {
        border-bottom: 1px solid rgba(133, 133, 133, 0.1) !important;
        .rbc-label {
          border-bottom: none !important;
        }
      }
      &:nth-child(4n + 2)::after {
        content: '';
        position: absolute;
        bottom: -15px;
        left: 25%;
        transform: translate(-50%, 0%);
        color: $primary;
        font-weight: bold;
        font-size: 24px;
      }
      &:nth-child(2)::after {
        content: '12';
        z-index: 999;
      }
      &:nth-child(6)::after {
        content: '13';
        z-index: 999;
      }
      &:nth-child(10)::after {
        content: '14';
        z-index: 999;
      }
      &:nth-child(14)::after {
        content: '15';
        z-index: 999;
      }
      &:nth-child(18)::after {
        content: '16';
        z-index: 999;
      }
      &:nth-child(22)::after {
        content: '17';
        z-index: 999;
      }
      &:nth-child(26)::after {
        content: '18';
        z-index: 999;
      }
      &:nth-child(30)::after {
        content: '19';
        z-index: 999;
      }
      &:nth-child(34)::after {
        content: '20';
        z-index: 999;
      }
      &:nth-child(38)::after {
        content: '21';
        z-index: 999;
      }
    }
  }
}

.calendarDay {
  .rbc-day-slot.rbc-time-column {
    min-width: 40px !important;
    .rbc-timeslot-group {
      .rbc-time-slot {
        min-width: unset !important;
        max-width: unset !important;
      }
    }
  }
}

.rbc-time-slot {
  text-align: right;
  .rbc-time-slot {
    border-top: none !important;
    border-bottom: none !important;
  }
  .rbc-label {
    font-weight: 600;
    font-size: 13px;
    border-left: 1px solid #858585 !important;
    border-bottom: 1px solid #858585 !important;
    height: 21px;
    display: inline-block;
    padding: 1px 15px;
    text-align: center;
    width: 50%;
  }
}

.rbc-month-row,
.rbc-event-content {
  overflow: visible;
}

.rbc-event-content {
  font-size: 12.5px;
  a {
    color: black;
    font-size: 11px;
  }
}

.rbc-row-segment {
  position: relative;
}

.rbc-time-header-gutter {
  min-width: 100px !important;
  max-width: 100px !important;
  background: $solitude !important;
  margin-right: 0 !important;
  &:first-child {
    border-right: 1px solid $hawkesBlue !important;
  }
}

.calendarWeek .rbc-time-header-content {
  padding: 0 !important;
  min-width: 141px !important;
}

.calendarDay {
  .rbc-time-header-content {
    min-width: 40.9px !important;
  }
}

.calendarDay div.rbc-time-header-content:nth-child(2) {
  .rbc-header {
    border-left: none !important;
  }
}

.calendarWeek div.rbc-time-header-content:nth-child(2) {
  min-width: 140px !important;
}

.rbc-header {
  padding: 0 !important;
  border-left: 0.5px solid rgba(133, 133, 133, 0.4) !important;
}

.rbc-time-column {
  max-width: 100% !important;
}

.rbc-day-slot {
  .rbc-events-container {
    margin-right: 0 !important;
  }
  .rbc-event {
    border: none !important;
    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
  }
  .rbc-event.rbc-addons-dnd-dragged-event {
    z-index: 8 !important;
  }
  .roomUnDroppable.rbc-addons-dnd-drag-preview {
    z-index: 8 !important;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
  }
}

.calendarWeek .rbc-day-slot {
  min-width: 140px !important;
}

.rbc-event-label {
  display: none;
}

.rbc-addons-dnd-resizable {
  width: 100%;
}
.rbc-event {
  padding: 0 !important;
  width: calc(100% - 2px) !important;
  left: calc(0% + 1px) !important;
}

.rbc-day-slot .rbc-event {
  border: 1px solid #f3f3f3 !important;
}

.rbc-event-label {
  display: none !important;
}

.rbc-addons-dnd-resizable {
  height: 100%;
  width: 100%;
  .active {
    height: 100%;
    padding: 4px;
  }
}

.rbc-selected {
  .active {
    padding-right: 8px;
    padding-bottom: 14px;
  }
}

.rbc-overflowing {
  margin-right: 6px !important;
}

.rbc-addons-dnd-resize-ns-icon {
  border-top: 5px double !important;
  width: 37px !important;
}

.rbc-addons-dnd-is-dragging {
  .rbc-events-container {
    margin-right: 10px !important;
  }
}

.disableResize {
  .rbc-addons-dnd-resize-ns-icon {
    display: none !important;
  }
}

@media (min-width: 2000px) {
  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg {
    width: unset !important;
  }
  .calendarDay .rbc-day-slot {
    min-width: 73.25px !important;
    max-width: 73.31px !important;
  }
  .calendarWeek div.rbc-time-header-content:nth-child(2) {
    border-left: 0px !important;
    min-width: 141px !important;
  }
}

@media (min-width: 2300px) {
  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg {
    width: unset !important;
  }
  .calendarDay div.rbc-time-header-content:nth-child(2) {
    border-left: 0px !important;
  }
  .calendarDay .rbc-time-header-content {
    min-width: 70.9 !important;
    max-width: unset !important;
  }
  .calendarDay .rbc-day-slot {
    max-width: unset !important;
  }
  .calendarWeek div.rbc-time-header-content:nth-child(2) {
    border-left: 0px !important;
    min-width: 141px !important;
  }
}

.rbc-time-header-content {
  border-left: none !important;
}

.rbc-time-header-content:nth-child(2) {
  border-left: 0px !important;
  margin-left: 0px !important;
}

@media (min-width: 992px) and (max-width: 1600px) {
  .calendarDay .rbc-day-slot {
    min-width: 25px !important;
  }
  .calendarDay .rbc-day-slot:last-child {
    min-width: 25px !important;
  }
  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg {
    width: unset !important;
  }
  .rbc-time-view-resources .rbc-time-header-content {
    flex-basis: 0px !important;
    flex-grow: 1 !important;
  }
  .rbc-overflowing {
    padding-right: 0 !important;
  }
  .rbc-time-slot .rbc-label {
    font-size: 10px !important;
  }
}

.search_add_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  img {
    display: none;
  }
  .ant-select-selector {
    padding: 0 11px !important;
  }
  .input-text {
    padding: unset !important;
  }
  .ant-input {
    padding: 4px 11px !important;
  }
  .ant-input-search {
    padding: unset !important;
  }
  .ant-input-group-addon {
    display: none;
  }
  .ant-picker-separator {
    height: 22px !important;
  }
  @media (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
  }
}

.logaction {
  table.table td {
    padding: 0.35rem !important;
  }
}

.search-form {
  display: flex;
  flex: 1;
  &__input {
    flex: 0 1 350px;
  }
  &__select {
    flex: 0 1 200px;
  }
  &__date {
    flex: 0 1 300px;
  }
  .ant-form-item {
    margin-right: 8px;
    &:last-child {
      margin-right: 0 !important;
    }
  }
  @media (max-width: 575px) {
    flex-direction: column;
    width: 100%;
    &__input,
    &__select,
    &__date {
      width: 100%;
      flex-basis: 0;
    }
  }
  @media (min-width: 576px) and (max-width: 767px) {
    width: 100%;
    &__input,
    &__select,
    &__date {
      flex: 1;
    }
  }
}

.ql-bg-black {
  background-color: #000;
}
.ql-bg-red {
  background-color: #e60000;
}
.ql-bg-orange {
  background-color: #f90;
}
.ql-bg-yellow {
  background-color: #ff0;
}
.ql-bg-green {
  background-color: #008a00;
}
.ql-bg-blue {
  background-color: #06c;
}
.ql-bg-purple {
  background-color: #93f;
}
.ql-color-white {
  color: #fff;
}
.ql-color-red {
  color: #e60000;
}
.ql-color-orange {
  color: #f90;
}
.ql-color-yellow {
  color: #ff0;
}
.ql-color-green {
  color: #008a00;
}
.ql-color-blue {
  color: #06c;
}
.ql-color-purple {
  color: #93f;
}
.ql-font-serif {
  font-family: Georgia, Times New Roman, serif;
}
.ql-font-monospace {
  font-family: Monaco, Courier New, monospace;
}
.ql-size-small {
  font-size: 0.75em;
}
.ql-size-large {
  font-size: 1.5em;
}
.ql-size-huge {
  font-size: 2.5em;
}
.ql-direction-rtl {
  direction: rtl;
  text-align: inherit;
}
.ql-align-center {
  text-align: center;
}
.ql-align-justify {
  text-align: justify;
}
.ql-align-right {
  text-align: right;
}
.ql-editor.ql-blank::before {
  color: rgba(0, 0, 0, 0.6);
  content: attr(data-placeholder);
  font-style: italic;
  left: 15px;
  pointer-events: none;
  position: absolute;
  right: 15px;
}
.ql-editor {
  max-height: 400px;
}

.roomTypeNurse {
  border-left: 2px solid red !important;
}
