@import '~styles/variables';

.headerWrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 65px;
  background-color: $irisBlue;
  padding: 0 10px;
  color: #fff;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  :global {
    .ant-menu-horizontal > .ant-menu-item,
    .ant-menu-horizontal > .ant-menu-submenu {
      top: 0 !important;
      a {
        bottom: 0 !important;
        color: #fff !important;
      }
    }
    .ant-menu-item {
      a {
        text-decoration: none;
      }
    }

    .anticon-ellipsis,
    .ant-menu-submenu-title {
      color: #fff !important;
    }

    .ant-menu-submenu-horizontal {
      border-bottom: 2px solid #fff !important;
      &::after {
        border-bottom: 2px solid #fff !important;
      }
    }
  }
}

.dropdown {
  padding: 12px 12px;
  :global {
    .ant-dropdown-menu-item {
      padding: 10px 0;
    }
  }
}

@media (max-width: 575px) {
  .logo {
    padding-left: 0.5rem !important;
    padding-right: 0 !important;
    height: 45px !important;
    img {
      height: 100%;
      width: auto;
    }
  }
  .navigation {
    :global {
      li.ant-menu-item {
        padding: 0 27px !important;
      }
      .ant-menu-item a {
        font-size: 0.7rem;
      }
      li.ant-menu-submenu {
        padding: 0 7px !important;
      }
    }
  }
}

@media (max-width: 375px) {
  .navigation {
    :global {
      li.ant-menu-item {
        padding: 0 15px !important;
      }
      .ant-menu-item a {
        font-size: 0.7rem;
      }
      li.ant-menu-submenu {
        padding: 0 5px !important;
      }
    }
  }
}

@media (max-width: 360px) {
  .navigation {
    :global {
      li.ant-menu-submenu {
        padding: 0px !important;
      }
    }
  }
}

.logo {
  display: block;
  height: 65px;
  font-size: 36px;
  font-weight: 700;
  color: #000 !important;
  background-color: $irisBlue;
  padding: 0px 20px 0px 40px;
  white-space: nowrap;
  display: flex;
  align-items: center;
  justify-content: center;
}

.navigation {
  background: $irisBlue;
  border-bottom: none;
  display: flex !important;
  align-items: center;
  font-weight: bold;
  font-size: $font-size-sm;
  max-width: calc(100vw - 150px);
  :global {
    .ant-menu-item {
      height: 100%;
      display: flex;
      align-items: center;
      border-bottom: none;
      margin: 0 0px !important;
      padding: 0 20px !important;
    }
    .ant-menu-item-selected {
      border-bottom: none !important;
      background-color: rgba($color: #ffffff, $alpha: 0.3);
      margin-bottom: 0;
      &:hover {
        background-color: rgba(255, 255, 255, 0.3);
      }
    }
    .ant-menu-item:hover {
      color: white;
      border-bottom-color: white;
    }
    .ant-menu-item-selected a {
      color: #ffffff !important;
      border-bottom: 0;
    }
    .ant-menu-item a:hover {
      color: #fff !important;
    }
  }
}

.menuResponsive {
  display: none !important;
}

.buttonHeader {
  display: flex;
  margin-right: 20px;
}

.timekeeping {
  margin-right: 10px;
  button {
    background-color: #d9962b;
    color: #fff;
    border: none;
    padding: 0 20px;
  }
}

.otregister {
  button {
    background-color: transparent;
    padding: 0 20px;
    color: #fff;
    border: transparent;
  }
}

.menuWrapper {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  .menuItem {
    padding: 10px;
    position: relative;
    cursor: pointer;
  }
  .avatar {
    display: flex;
    line-height: 20px;
    span {
      width: 39px;
      height: 39px;
      margin-right: 8px;
      font-size: 30px;
    }
  }
}

.name {
  display: flex;
  align-self: center;
  white-space: nowrap;
}

@media (min-width: 1150px) {
  .navigation {
    width: calc(100vw - 500px) !important;
  }
  .ant-menu-overflowed-submenu {
    display: none;
    opacity: 0;
    visibility: hidden;
  }
}
// @media (max-width: 1150px) {
//     li.ant-menu-item {
//         display: none !important;
//     }
//     li.menuResponsive {
//         display: block !important;
//     }
// }

@media print {
  .headerWrapper {
    display: none;
  }
}