body:has(div.bg-white) {
  overflow: auto;
}

.calendarDay .rbc-time-slot:not(.roomDroppable) {
  z-index: 10 !important;
  background: #ddd;
  cursor: no-drop;
}

.modal-dialog {
  min-width: 360px;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}
