.buttonReload {
  position: absolute;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffffff;
  box-shadow: 0 4px 15px #0000001a;
  bottom: 10px;
  left: 10px;
  cursor: pointer;
  z-index: 900;
}
