.pageWrapper {
  display: flex;
  padding-top: 65px;
}

.mainWrapper {
  height: 100%;
  flex: 1 1;
}

.pageContent {
  flex: 1 1;
  padding: 20px;
  // background: #fff;
  max-height: calc(100vh - 50px);
  overflow-x: hidden;
  overflow-y: auto;
}
@media print {
  .pageContent {
    max-height: initial;
  }
}
