// Variable
$font-family-sans-serif: 'Roboto', 'Helvetica', 'Arial', sans-serif;
$font-family-monospace: 'Times New Roman', Times, serif;
$font-family-base: $font-family-sans-serif;
$font-size-base: 0.875rem;
$font-size-normal: $font-size-base * 1;
$font-size-lg: $font-size-base * 1.25;
$font-size-md: $font-size-base * 1.125;
$font-size-sm: $font-size-base * 0.875;
$font-size-xs: $font-size-base;
$font-size-xl: $font-size-base * 1.35;
$font-size-icon-nav: $font-size-base * 1.5;
$font-weight-light: 300;
$font-weight-normal: 500;
$font-weight-bold: 700;
$sidebar-size: 250px;
$sidebar-mini-size: 56px;
$primary: #008faa;
$secondline: #858585;
$irisBlue: #11b3d1;
$hawkesBlue: #c5dcff;
$solitude: #e1edff;
$orchid: #85cfdd;
$bondiBlue: #0192ca;
$dark-blue: #0d20cf;
$gray-light: #d3d3d3;
$torea: #51bbcf;

// start custom padding
@mixin p($top, $right, $bottom, $left) {
  padding-top: $top + px !important;
  padding-right: $right + px !important;
  padding-bottom: $bottom + px !important;
  padding-left: $left + px !important;
}

@mixin pl($left) {
  padding-left: $left + px !important;
}

@mixin pr($right) {
  padding-right: $right + px !important;
}

@mixin pb($bottom) {
  padding-bottom: $bottom + px !important;
}

@mixin pt($top) {
  padding-top: $top + px !important;
}

@mixin ptb($top, $bottom) {
  padding-top: $top + px !important;
  padding-bottom: $bottom + px !important;
}

@mixin plr($left, $right) {
  padding-left: $left + px !important;
  padding-right: $right + px !important;
}

@for $i from 0 to 150 {
  .p-#{$i} {
    @include pt($i);
    @include pr($i);
    @include pl($i);
    @include pb($i);
  }
  .pt-#{$i} {
    @include pt($i);
  }
  .pr-#{$i} {
    @include pr($i);
  }
  .pb-#{$i} {
    @include pb($i);
  }
  .pl-#{$i} {
    @include pl($i);
  }
  .plr-#{$i} {
    @include pl($i);
    @include pr($i);
  }
  .ptb-#{$i} {
    @include pt($i);
    @include pb($i);
  }
}

// end custom padding
// start custom margin
@mixin m($top, $right, $bottom, $left) {
  margin-top: $top + px !important;
  margin-right: $right + px !important;
  margin-bottom: $bottom + px !important;
  margin-left: $left + px !important;
}

@mixin mt($top) {
  margin-top: $top + px !important;
}

@mixin ml($left) {
  margin-left: $left + px !important;
}

@mixin mr($right) {
  margin-right: $right + px !important;
}

@mixin mb($bottom) {
  margin-bottom: $bottom + px !important;
}

@mixin mtb($top, $bottom) {
  margin-top: $top + px !important;
  margin-bottom: $bottom + px !important;
}

@mixin mlr($left, $right) {
  margin-left: $left + px !important;
  margin-right: $right + px !important;
}

@for $i from 0 to 200 {
  .m-#{$i} {
    @include mt($i);
    @include mr($i);
    @include ml($i);
    @include mb($i);
  }
  .mt-#{$i} {
    @include mt($i);
  }
  .mr-#{$i} {
    @include mr($i);
  }
  .mb-#{$i} {
    @include mb($i);
  }
  .ml-#{$i} {
    @include ml($i);
  }
  .mlr-#{$i} {
    @include ml($i);
    @include mr($i);
  }
  .mtb-#{$i} {
    @include mt($i);
    @include mb($i);
  }
}

// custom fontsize
@mixin fontsize($size) {
  font-size: $size + px !important;
}

@for $i from 8 to 54 {
  .fontsize-#{$i} {
    @include fontsize($i);
  }
}

@for $i from 8 to 54 {
  .heading-#{$i} {
    @include fontsize($i);
    font-weight: bold !important;
  }
}

.font-size-lg {
  font-size: $font-size-lg;
}

.bgColor-1 {
  background-color: #79ea7d;
}

.bgColor-2 {
  background-color: #fff978;
}

.bgColor-3 {
  background-color: #ff883c;
}

.bgColor-4 {
  background-color: #fffffe;
}

.bgColor-5 {
  background-color: #74f3ff;
}

.bgColor-6 {
  background-color: #000fff;
}

.bgColor-7 {
  background-color: #000000;
}

.bgColor-8 {
  background-color: #ff8b80;
}

.bgColor-9 {
  background-color: #8286ff;
}

.bgColor-10 {
  background-color: #fd0000;
}

.bgColor-11 {
  background-color: #fd03fd;
}

.bgColor-12 {
  background-color: #ffffff;
}

.bgColor-13 {
  background-color: #c1c1c1;
}

.bgColor-14 {
  background-color: #c2c2c2;
}

.bgColor-15 {
  background-color: #e3a909;
}

.bgColor-16 {
  background-color: #31ada9;
}

.bgColor-17 {
  background-color: #04d2d9;
}

.bgColor-18 {
  background-color: #aaa832;
}

.bgColor-19 {
  background-color: #a180ac;
}

.bgColor-20 {
  background-color: #DBBFBD;
}

.color-gray-1 {
  color: #333;
}

.border-select-1 {
  .ant-select-selector {
    border: 1px solid #51bbcf !important;
  }
  .ant-select-arrow {
    color: black;
  }
}

.btn-white.color-mulberry {
  color: #51bbcf !important;
}

.font-weight-normal {
  font-weight: $font-weight-normal;
}

.fit-content {
  width: fit-content !important;
}

.width-20 {
  width: 20px;
}

.width-80 {
  max-width: 80%;
}

.width-100 {
  width: 100%;
}

.width100 {
  width: 100px !important;
}

.line-height-1 {
  line-height: 1;
}

.boxCourse {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 10px;
  border-radius: 5px;
  background: #ffffff;
}

.plan-bg-1 {
  background: #e3e3e3;
}

.plan-bg-2 {
  background: #fff3e2;
}

.plan-bg-3 {
  background: #e1edff;
}

.plan-bg-4 {
  background: #ffb6c1;
}

.min-width-454 {
  min-width: 454px !important;
}

.max-width-1200 {
  margin: auto;
  max-width: 1200px;
}

.top-50 {
  top: 50%;
  transform: translateY(-50%);
}

.height-100 {
  height: 100%;
}

.break-space {
  white-space: break-spaces;
}

.link-black {
  color: black !important;
}
