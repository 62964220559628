@font-face {
  font-family: 'NotoSansJP';
  src: url('../assets/fonts/NotoSansJP-Bold.otf');
  font-weight: bold;
}

@font-face {
  font-family: 'NotoSansJP';
  src: url('../assets/fonts/NotoSansJP-Medium.otf');
  font-weight: 500;
}

// Other styles
body {
  overflow-y: hidden;
  word-break: break-word;
  word-wrap: break-word;
  color: #000000 !important;
  font-family: 'NotoSansJP', Arial, Helvetica, sans-serif;
}

#root {
  font-family: 'NotoSansJP', Arial, Helvetica, sans-serif;
}

ul {
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

// start line-clamp
.line-1 {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-2 {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-3 {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-4 {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-5 {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.line-6 {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

//Font
.text-direction {
  display: inline-block;
  color: #0192ca;
  border-bottom: 1px solid #0192ca;
  cursor: pointer;
  line-height: 20px;
}

.no-wrap {
  white-space: nowrap;
}

//Width
.full-width {
  width: 100%;
}

.modal-full-width {
  .modal-dialog {
    width: 80vw;
    max-width: 80vw;
    margin: auto;
  }
  .ant-select {
    min-width: 40%;
  }
}

//Table
.table-class {
  border-top: 2px solid #a4a4a4 !important;
  & tr:focus {
    background-color: #e8eaff !important;
  }
}

table.table {
  border-color: #c5dcff !important;
  border-top: 1px solid #c5dcff !important;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid;
  border-color: #c5dcff !important;
}

.row-selected {
  background-color: #e8eaff !important;
}

//position
.absolute-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

//btn
.cursor-pointer {
  cursor: pointer;
}

.btn {
  font-size: 0.76562rem;
  font-weight: 700;
  line-height: 1.5;
  border-radius: 0.2rem;
  min-width: fit-content;
  min-height: 36px;
  transition: 0.7s;
}

.btnBondiBlue {
  color: white;
  background-color: $bondiBlue;
  font-weight: bold;
  &:hover {
    color: white;
    background-color: $bondiBlue;
    font-weight: bold;
  }
}

.btnOrchid {
  color: black;
  background-color: rgba(133, 207, 221, 0.3);
  font-weight: bold;
}

.btnModal {
  max-width: 200px;
  width: 100%;
  padding: 9px 10px;
  border: 1px solid $primary;
  font-weight: bold;
  &:hover {
    opacity: 0.9;
  }
}

.btnModal.bgBue1 {
  background: $primary;
  color: white;
}

.btnModal.bgBorder {
  background: white;
  color: $primary;
}

.buttonDefault {
  display: flex !important;
  align-items: center;
}

.trashWrapper {
  text-align: right;
  position: absolute;
  right: 18px;
  z-index: 99;
}

.trashWrapperTerm {
  text-align: right;
  position: absolute;
  right: 9px;
  z-index: 99;
  top: 5px;
}

.stepBlock {
  .trashWrapper {
    text-align: right;
    position: absolute;
    right: 18px;
    z-index: 99;
  }
}

.opacity-3 {
  opacity: 0.3;
}

//tooltip
.tooltip-custom {
  position: relative;
  display: inline-block;
  width: 100%;
  .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: #fff;
    color: #000;
    font-weight: 500;
    font-size: 11px;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
  }
  &:hover .tooltiptext {
    visibility: visible;
  }
}

//Pure css
::-webkit-scrollbar-thumb {
  background: #aeaeae;
  border-radius: 5px;
}

::-webkit-scrollbar {
  width: 7px;
  height: 12px;
}

::-moz-scrollbar {
  width: 7px;
  height: 12px;
}

::-mz-scrollbar {
  width: 7px;
  height: 12px;
}

::-webkit-scrollbar-track {
  background: #d5d5d5;
}

.rbc-time-content::-webkit-scrollbar {
  background-color: #e1edff;
}

.rbc-time-content::-webkit-scrollbar-track {
  background: #d5d5d5;
  // margin-left: 100px;
}

// Heading
.fontsize-14 {
  font-size: 14px;
}

.fontsize-15 {
  font-size: 15px;
}

.fontsize-16 {
  font-size: 16px;
}

.fontsize-17 {
  font-size: 17px;
}

.fontsize-18 {
  font-size: 18px;
}

.fontsize-20 {
  font-size: 20px;
}

.fontsize-24 {
  font-size: 24px;
}

.heading-14 {
  font-size: 14px;
  font-weight: bold;
}

.heading-15 {
  font-size: 15px !important;
  font-weight: bold;
}

.heading-16 {
  font-size: 16px;
  font-weight: bold;
}

.heading-17 {
  font-size: 17px;
  font-weight: bold;
}

.heading-18 {
  font-size: 18px;
  font-weight: bold;
}

.heading-20 {
  font-size: 20px;
  font-weight: bold;
}

.heading-24 {
  font-size: 24px;
  font-weight: bold;
}

.text-medium {
  font-weight: 600;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-25 {
  margin-bottom: 25px;
}

// flex
.flex-center-between {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.flex-center {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.flex-row {
  flex-direction: row;
}

.flex-column {
  flex-direction: column;
}

.flex-basis-25 {
  flex-basis: 25%;
}

.flex-basis-50 {
  flex-basis: 50%;
}

.flex-basis-125 {
  flex-basis: 125%;
}

.flex-wrap {
  flex-wrap: wrap;
}

//hide - show
.hide {
  display: none;
}

body {
  background-color: #f5f6fa !important;
  .BtnAdd {
    background: #0192ca !important;
    padding: 5px 15px;
    height: auto;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #0192ca;
  }
  .BtnAdd:hover,
  .BtnAdd:focus {
    color: #ffffff;
    border: 1px solid #0192ca;
  }
  .BtnAddPlan {
    background: #0192ca !important;
    padding: 5px 15px;
    min-width: 110px;
    height: auto;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #0192ca;
    opacity: 1 !important;
  }
  .BtnAddPlan:hover,
  .BtnAddPlan:focus {
    color: #ffffff;
    border: 1px solid #0192ca;
  }
  .btnOperating {
    border: none;
    padding: 3px 5px;
    background: transparent;
  }
  .btn-general {
    min-width: 200px;
    text-align: center;
    border-radius: 25px;
    background: #51bbcf;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    border: none;
    height: 50px;
    &.ant-btn:hover,
    &.ant-btn:focus,
    &:hover {
      background: #51bbcf;
      color: #ffffff;
      opacity: 0.8;
    }
  }
  .btn-danger {
    background-color: rgba(255, 0, 0, 0.699);
  }
  .BtnCSV {
    background: #85cfdd4d !important;
    padding: 5px 15px;
    height: auto;
    color: #000000;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #85cfdd4d;
    &:hover {
      background: #85cfdd4d;
      color: #000000;
      border: 1px solid #85cfdd4d;
      opacity: 0.8;
    }
  }
  .btn-cancel {
    background: transparent !important;
    color: #51bbcf !important;
    border: 1px solid #51bbcf;
    min-width: 130px;
  }
  .btn-white {
    background: #fff !important;
    color: black !important;
    border: 1px solid #51bbcf;
  }
  .BtnAdd {
    background: #0192ca !important;
    padding: 5px 15px;
    height: auto;
    color: #ffffff;
    font-size: 16px;
    font-weight: bold;
    border-radius: 5px;
    border: 1px solid #0192ca;
  }
  .BtnAdd:hover,
  .BtnAdd:focus {
    color: #ffffff;
    border: 1px solid #0192ca;
  }
  .btnOperating {
    border: none;
    padding: 3px 5px;
    height: 30px;
    width: 35px;
    display: block;
    background: transparent;
  }
  .btn-general {
    min-width: 200px;
    text-align: center;
    border-radius: 25px;
    background: #51bbcf !important;
    color: #ffffff;
    font-size: 15px;
    font-weight: 700;
    border: none;
    height: 50px;
    &.ant-btn:hover,
    &.ant-btn:focus,
    &:hover {
      background: #51bbcf;
      color: #ffffff;
      opacity: 0.8;
    }
  }
  .btn-general-none {
    min-width: 200px;
    text-align: center;
    border-radius: 25px;
    color: #000 !important;
    font-size: 15px;
    font-weight: 700;
    border-color: #51bbcf !important;
    height: 50px;
  }
  .btn-white {
    background: #fff !important;
    color: black !important;
    border: 1px solid #51bbcf;
  }
  .btn-normal {
    font-size: 0.76562rem;
    font-weight: 700;
    line-height: 1.5;
    border-radius: 0.2rem;
    min-width: 168px;
    min-height: 40px;
    transition: 0.7s;
  }
}

.text-nowrap {
  white-space: nowrap;
}

.text-nowrap-disabled {
  white-space: nowrap;
  background-color: lightgray;
}

.breadcrumb {
  border-bottom: none;
  padding: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
  content: '>';
  color: #9a9a9a;
}

.breadcrumb-item.active,
.breadcrumb-item {
  color: #9a9a9a;
}

.mw-690 {
  max-width: 710px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 4px 15px #0000001a;
  border-radius: 10px;
  background-color: white;
}

.mw-455 {
  max-width: 455px;
}

.mw-926 {
  max-width: 926px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 4px 15px #0000001a;
  border-radius: 10px;
}

.mw-200 {
  min-width: 200px !important;
}

.mw-100 {
  width: 100%;
}

.mh-40 {
  max-height: 40px;
}

.boxForm {
  max-width: 490px;
  margin-right: auto;
  margin-left: auto;
  padding: 30px 15px;
}

.w100px {
  max-width: 100px;
  width: 100px;
}

.mw-700 .modal-body {
  width: 700px;
}

.modal-700 .modal-dialog {
  max-width: 700px;
  width: 100%;
}

.mw-900 .modal-body {
  width: 900px;
}

.modal-900 .modal-dialog {
  max-width: 900px;
  width: 100%;
}

.modal-700 .modal-body {
  max-width: 455px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
}

.modal-450 .modal-dialog {
  max-width: 450px;
  width: 100%;
}

.fillter_head .input-text {
  padding: 6.5px 10px;
}

.fillter_head .ant-select-selector {
  padding: 0 10px !important;
}

.box {
  display: inline-block;
  width: 25px;
  height: 25px;
  border: 1px solid white;
  margin-right: 10px;
  border-color: #444 !important;
}

.linkBlack {
  color: black;
}

.link {
  color: #4c85ef;
}

.underline {
  text-decoration: underline;
}

.formLogin {
  .ant-input-affix-wrapper,
  .ant-input {
    border-radius: 0 !important;
  }
}

.text-bold {
  font-weight: bold;
}

.letter-spacing-1 {
  letter-spacing: -1px;
}

.br-5 {
  border-radius: 5px;
}

a.white {
  color: white;
  &:visited {
    color: white;
  }
}

.blueCard {
  background: #f5fdff;
  border: 1px solid $hawkesBlue;
  padding: 17px;
}

.planBock {
  position: relative;
  background: $solitude;
  padding: 18px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  min-height: 82px;
}

.iconGroup {
  display: flex;
}

.stepBlock {
  position: relative;
  background: $solitude;
  padding: 18px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  max-height: 60px;
  display: flex;
  cursor: pointer;
  align-items: center;
  input[readonly] {
    background: #e1edff !important;
    border: none !important;
    box-shadow: none !important;
    cursor: pointer !important;
  }
}

.termBlock {
  position: relative;
  background: #f1f1f1;
  padding: 18px;
  font-size: 15px;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  cursor: default !important;
  input[readonly] {
    background: #f1f1f1 !important;
    border: none !important;
    box-shadow: none !important;
    cursor: default !important;
  }
}

.termContentBlock {
  position: relative;
  background: #f1f1f1;
  padding: 18px;
  font-size: 15px;
  font-weight: bold;
  border-radius: 5px;
  border: 1px solid #c3c3c3;
  cursor: default !important;
  input[readonly] {
    background: #f1f1f1 !important;
    border: none !important;
    box-shadow: none !important;
    cursor: default !important;
  }
  .ant-checkbox-wrapper {
    :global(.ant-checkbox-wrapper) {
      margin-bottom: 0 !important;
    }
  }
}

.checkboxCustom {
  display: flex !important;
  flex-direction: column;
  .ant-checkbox-wrapper {
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    &::after {
      display: none;
    }
  }
  .ant-checkbox-group-item {
    margin-right: 0 !important;
    margin-bottom: 16px;
  }
  .ant-checkbox-inner {
    // border-radius: 50%;
    width: 30px;
    height: 30px;
    &::after {
      width: 8px !important;
      height: 17px !important;
      top: 49% !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #51bbcf !important;
    border-color: #51bbcf !important;
  }
}

.steps {
  overflow: auto;
  height: 100vh;
}

.step-title {
  font-size: 30px;
  font-weight: bold;
  text-align: center;
}

.step-guide {
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 54px;
}

.term-container {
  border: 1px solid #c5dcff;
  // height: calc(100vh - 700px);
  min-height: 746px;
  margin: 70px 136px 0;
  padding: 30px;
}

.termForm {
  margin: auto;
  border: 1px solid #c5dcff;
  max-width: 1200px;
  margin-top: 70px;
  .ant-form-item {
    margin-bottom: 0 !important;
    padding-top: 40px;
  }
  .ant-input {
    border: 1px solid #c3c3c3 !important;
    &::placeholder {
      color: #8d8181 !important;
      opacity: 1 !important;
    }

    &:-ms-input-placeholder {
      color: #8d8181 !important;
      opacity: 1 !important;
    }

    &::-ms-input-placeholder {
      color: #8d8181 !important;
      opacity: 1 !important;
    }
  }
}

.mw-1200 {
  max-width: 1200px;
  margin: auto !important;
}

.bg-blue {
  background: #e1edff;
}

.require {
  color: #f44336;
  font-size: 13px;
  border: 1px solid #f44336;
  border-radius: 3px;
  padding: 0 3px;
  margin-left: 5px;
  font-weight: 500;
}

.detail-title {
  margin-bottom: 10px;
  font-size: 24px;
  font-weight: bold;
}

.btn-grey {
  min-width: 215px;
  text-align: center;
  border-radius: 25px;
  background: #dbdbdb;
  color: #ffffff;
  font-size: 15px;
  font-weight: 700;
  border: none;
  height: 50px;
  &.ant-btn:hover,
  &.ant-btn:focus,
  &:hover {
    color: #ffffff;
    opacity: 0.8;
  }
}

.break-all {
  white-space: break-spaces !important;
}

.checkboxCustom-2 {
  display: flex !important;
  flex-direction: column;
  font-size: 15px;
  font-weight: 550;
  span {
    white-space: break-spaces;
  }
  .ant-checkbox {
    margin-right: 10px;
  }
  .ant-checkbox-group-item {
    margin-left: 20px !important;
  }
  .ant-checkbox-wrapper {
    align-items: center;
    justify-content: space-between;
  }
  .ant-checkbox-group-item {
    margin-right: 0 !important;
    margin-bottom: 16px;
  }
  .ant-checkbox-inner {
    // border-radius: 50%;
    width: 30px;
    height: 30px;
    &::after {
      width: 8px !important;
      height: 17px !important;
      top: 49% !important;
    }
  }
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: #51bbcf !important;
    border-color: #51bbcf !important;
  }
}

.checkboxCustom-2.flex-start {
  .ant-checkbox-group-item {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
  }
}

.radioCustom {
  display: flex !important;
  flex-direction: column;
  .ant-radio-wrapper {
    display: flex !important;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    margin-right: 0 !important;
  }
  .ant-radio-group-item {
    margin-right: 0 !important;
    margin-bottom: 16px;
  }
  .ant-radio-inner {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    &::after {
      content: none;
    }
    &::before {
      width: 8px !important;
      height: 17px !important;
      top: 48% !important;
      left: 5.5px;
      position: absolute;
      display: table;
      border: 2px solid #fff;
      border-top: 0;
      border-left: 0;
      -webkit-transform: rotate(45deg) scale(1) translate(-50%, -50%);
      transform: rotate(45deg) scale(1) translate(-50%, -50%);
      opacity: 1;
      -webkit-transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
      content: ' ';
    }
  }
  .ant-radio-checked .ant-radio-inner {
    background-color: #51bbcf !important;
    border-color: #51bbcf !important;
  }
}

.radioCustom-2 {
  display: flex !important;
  align-items: center;
  .ant-radio-wrapper {
    display: flex;
    align-items: center;
    margin-right: 40px !important;
  }
  .ant-radio-group-item {
    margin-right: 0 !important;
    margin-bottom: 16px;
  }
  .ant-radio-inner {
    border-radius: 50%;
    width: 30px;
    height: 30px;
    border-color: #008faa !important;
    display: flex;
    align-items: center;
    justify-content: center;
    // &::after {
    //     background-color: #51bbcf !important;
    //     border-color: #51bbcf !important;
    //     content: ' ';
    //     top: 50% !important;
    //     left: 50% !important;
    //     transform: translate(-50%, -50%);
    //     border-radius: 50%;
    //   }
  }
  .ant-radio-checked .ant-radio-inner {
    &::after {
      background-color: #008faa !important;
      border-color: #008faa !important;
      content: ' ';
      width: 80% !important;
      height: 80% !important;
      border-radius: 50%;
    }
  }
}

.select-custom {
  min-width: 100% !important;
  .ant-select-selector {
    height: 45px !important;
  }
  .ant-select-selection-item {
    display: flex;
    align-items: center;
  }
}

.mbf-0 {
  margin-bottom: 0 !important;
}

.email {
  white-space: nowrap;
}

.bg-header > img {
  min-height: 340px;
}

.img-cover {
  object-fit: cover;
  height: 100%;
  width: 100%;
  display: block;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
}

.header-text {
  font-weight: 700;
  color: #00bcd1;
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -40%);
  width: 100%;
  text-align: center;
  padding: 20px;
}

.fontsize-52 {
  font-size: 52px;
}

.header {
  position: relative;
}

.bg-white {
  background: white;
  color: black !important;
}

.height-455 {
  min-height: calc(100vh - 454px);
  @media (max-width: 1199px) {
    & {
      height: calc(100vh - 340px);
    }
  }
}

@media (max-width: 575px) {
  .bg-header > img {
    min-height: 250px;
  }
  .fontsize-52 {
    font-size: 28px;
  }
}

@media (min-width: 576px) and (max-width: 767px) {
  .fontsize-52 {
    font-size: 32px;
  }
  .bg-header > img {
    min-height: 300px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .fontsize-52 {
    font-size: 44px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .fontsize-52 {
    font-size: 48px;
  }
}

.dragContext {
  width: 100%;
}

.outline-none {
  outline: none;
}

.col-title {
  padding-top: 50px !important;
  padding-left: 40px !important;
  background: #e1edff;
  font-weight: bold;
  font-size: 16px;
}

.resize-none {
  resize: none;
}

.button-term-form {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 74px;
  max-width: 1200px;
  align-items: center;
  margin: auto;
}

.button-term-steps {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
  padding-bottom: 74px;
  margin-left: 136px;
  margin-right: 136px;
}

.col-title-last {
  padding-top: 50px;
  padding-left: 40px;
  padding-bottom: 65px;
  font-weight: bold;
  font-size: 16px;
  background: #e1edff;
}

@media (max-width: 1200px) {
  .termForm,
  .button-term-form {
    margin-left: 70px;
    margin-right: 70px;
  }
}

@media (max-width: 992px) {
  .term-container,
  .button-term-steps {
    margin-left: 60px;
    margin-right: 60px;
  }
  .col-title {
    padding-left: 20px !important;
    padding-top: 40px !important;
  }
  .col-title-last {
    padding-left: 20px !important;
    padding-top: 40px !important;
  }
}

@media (max-width: 768px) {
  .term-container {
    margin-left: 40px;
    margin-right: 40px;
    padding: 15px;
  }
  .button-term-steps {
    flex-direction: column;
    margin-left: 70px !important;
    margin-right: 70px !important;
    align-items: center;
    .btn-general,
    .btn-grey {
      max-width: 454px;
      width: 100%;
      margin-bottom: 10px;
    }
  }
  .bg-white {
    .box-form {
      padding: 20px;
    }
    .termForm {
      margin-left: 20px;
      margin-right: 20px;
      .pl-40 {
        padding-left: 20px !important;
        padding-right: 20px;
      }
      .ant-form-item {
        padding-top: 10px;
      }
    }
    .col-title {
      background: none;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-top: 30px !important;
    }
    .col-title-last {
      background: none;
      padding-left: 20px !important;
      padding-right: 20px !important;
      padding-bottom: 0 !important;
      padding-top: 30px !important;
    }
    .btn-general {
      max-width: 454px;
      width: 100%;
      margin-bottom: 10px;
    }
    .button-term-form {
      flex-direction: column;
      margin-left: 20px !important;
      margin-right: 20px !important;
      align-items: center;
    }
  }
}

@media (max-width: 575px) {
  .term-container {
    margin-left: 20px;
    margin-right: 20px;
  }
  .button-term-steps {
    flex-direction: column;
    margin-left: 20px !important;
    margin-right: 20px !important;
    align-items: center;
  }
  .bg-white {
    div.button-term-form {
      flex-direction: column;
      margin-left: 20px !important;
      margin-right: 20px !important;
      align-items: center;
    }
  }
}

.slot-today {
  background-color: #eaf6ff !important;
}

.hidden {
  display: none !important;
}

.block-booking {
  background-color: #ced2d8 !important;
  border-color: #b4bac0 !important;
  border-top: 0 !important;
  cursor: no-drop;
}

.has-block-booking {
  .rbc-timeslot-group {
    border: none !important;
  }
  .block-booking {
    border: none !important;
  }
}

.red {
  color: red;
}

.checkin-number {
  color: red;
  border: 2px solid red;
  border-radius: 50%;
  width: -moz-fit-content;
  display: inline-flex;
  align-items: flex-start;
  justify-content: center;
  min-width: 17px;
  min-height: 17px;
  margin-left: auto;
  font-size: 12px;
  line-height: 1;
  font-weight: bold;
  white-space: nowrap;
}

.box-right {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

.checkin-number-tooltip {
  color: red;
  padding-left: 2px;
  padding-right: 2px;
  border: 2px solid red;
  border-radius: 50%;
  width: fit-content;
  line-height: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@page {
  size: auto !important;
}

// [data-reactroot] {
//   height: 100% !important;
// }

.calendarDayPrint {
  .rbc-time-content {
    max-height: unset !important;
    overflow-y: hidden !important;
  }
  .rbc-time-gutter {
    .rbc-timeslot-group {
      &:nth-child(2)::after {
        content: '08';
        z-index: 999;
      }
      &:nth-child(6)::after {
        content: '09';
        z-index: 999;
      }
      &:nth-child(10)::after {
        content: '10';
        z-index: 999;
      }
      &:nth-child(14)::after {
        content: '11';
        z-index: 999;
      }
      &:nth-child(18)::after {
        content: '12';
        z-index: 999;
      }
      &:nth-child(22)::after {
        content: '13';
        z-index: 999;
      }
      &:nth-child(26)::after {
        content: '14';
        z-index: 999;
      }
      &:nth-child(30)::after {
        content: '15';
        z-index: 999;
      }
    }
  }
  // .rbc-timeslot-group {
  //   min-height: 17px !important;
  //   max-height: 17px !important;
  //   height: 17px !important;
  // }
  .rbc-time-slot {
    display: flex;
    flex-direction: row-reverse;
  }
  .rbc-time-slot .rbc-label {
    // min-height: 17px !important;
    // max-height: 17px !important;
    // height: 17px !important;
    padding-top: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rbc-day-slot {
    .rbc-timeslot-group {
      &:nth-child(4n) {
        border-bottom: unset !important;
        .rbc-label {
          border-bottom: none !important;
          // height: 41px !important;
          // height: 19.8px !important;
        }
      }
    }
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 0 !important;
    padding: 0 !important;
  }
  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg {
    width: unset !important;
  }
  .rbc-time-header-content {
    // width: calc((100% - 100px) / 8) !important;
    max-width: unset !important;
    margin: 0 !important;
    border-left: 0.25px solid rgba(133, 133, 133, 0.4) !important;
    &:nth-child(2) {
      border-left: 0 !important;
    }
  }
  .rbc-day-slot.rbc-time-column {
    // width: calc((100% - 99px) / 8) !important;
    min-width: unset !important;
    max-width: unset !important;
  }
  .rbc-time-gutter {
    border-right: 1px solid #c5dcff !important;
  }
  .rbc-timeslot-group {
    max-height: unset !important;
    height: unset !important;
  }
  .calendarDay .rbc-day-slot {
    max-width: unset !important;
  }
  &.noGutter {
    .rbc-time-header-gutter,
    .rbc-time-gutter {
      display: none !important;
      z-index: 999;
    }
    .rbc-day-slot.rbc-time-column {
      // width: calc(100% / 8) !important;
      min-width: unset !important;
      max-width: unset !important;
    }
  }
  &.noHeader {
    .rbc-time-header {
      display: none !important;
      z-index: 999;
    }
    .rbc-time-gutter {
      border-top: 1px solid #cac0c0 !important;
      &:nth-child(1) {
        border-top: 0 !important;
      }
      background: $solitude !important;
      .rbc-timeslot-group {
        position: relative;
        border-bottom: 0 !important;
        border-left: none !important;
        &:nth-child(4n + 1) {
          .rbc-label {
            background: #f3f3f3;
          }
        }
        &:nth-child(4n + 2) {
          .rbc-label {
            background: #f3f3f3;
          }
        }
        &:nth-child(4n) {
          border-bottom: 1px solid #000 !important;
          .rbc-label {
            border-bottom: none !important;
            // height: 41px !important;
            height: 19.8px !important;
          }
        }
        &:nth-child(4n + 2)::after {
          content: '';
          position: absolute;
          bottom: -15px;
          left: 25%;
          transform: translate(-50%, 0%);
          color: $primary;
          font-weight: bold;
          font-size: 24px;
          z-index: 999;
        }
        &:nth-child(2)::after {
          content: '16';
          z-index: 999;
        }
        &:nth-child(6)::after {
          content: '17';
          z-index: 999;
        }
        &:nth-child(10)::after {
          content: '18';
          z-index: 999;
        }
        &:nth-child(14)::after {
          content: '19';
          z-index: 999;
        }
        &:nth-child(18)::after {
          content: '20';
          z-index: 999;
        }
        &:nth-child(22)::after {
          content: '21';
          z-index: 999;
        }
        &:nth-child(26)::after {
          content: '22';
          z-index: 999;
        }
        &:nth-child(30)::after {
          content: '23';
          z-index: 999;
        }
      }
    }
  }
}

.event-selected {
  border: 2px solid black;
}

.rbc-selected {
  .event-selected {
    border: none !important;
  }
}

.event-selected-2light {
  outline: 1px solid red !important;
  opacity: 0.9;
  transform: scale(1.2);
  left: 0 !important;
  width: calc(100% - 3px) !important;
  z-index: 1000;
}

@media print {
  html,
  body {
    height: initial !important;
    overflow: initial !important;
    background: white !important;
  }
  .rbc-time-content {
    max-height: unset !important;
    overflow: hidden !important;
  }
  .ant-tabs-nav {
    display: none !important;
  }
}

@page {
  size: 100vw 100vh;
}

@media all {
  .pagebreak {
    display: none;
  }
}

@media print {
  .pagebreak {
    page-break-before: always;
  }
}

.calendarDayPrintConsult {
  .rbc-time-content {
    max-height: unset !important;
    overflow-y: hidden !important;
  }
  .rbc-time-gutter {
    .rbc-timeslot-group {
      &:nth-child(2)::after {
        content: '08';
        z-index: 999;
      }
      &:nth-child(6)::after {
        content: '09';
        z-index: 999;
      }
      &:nth-child(10)::after {
        content: '10';
        z-index: 999;
      }
      &:nth-child(14)::after {
        content: '11';
        z-index: 999;
      }
      &:nth-child(18)::after {
        content: '12';
        z-index: 999;
      }
      &:nth-child(22)::after {
        content: '13';
        z-index: 999;
      }
      &:nth-child(26)::after {
        content: '14';
        z-index: 999;
      }
      &:nth-child(30)::after {
        content: '15';
        z-index: 999;
      }
    }
  }
  // .rbc-timeslot-group {
  //   min-height: 17px !important;
  //   max-height: 17px !important;
  //   height: 17px !important;
  // }
  .rbc-time-slot {
    display: flex;
    flex-direction: row-reverse;
  }
  .rbc-time-slot .rbc-label {
    // min-height: 17px !important;
    // max-height: 17px !important;
    // height: 17px !important;
    padding-top: 0 !important;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .rbc-day-slot {
    .rbc-timeslot-group {
      &:nth-child(4n) {
        border-bottom: unset !important;
        .rbc-label {
          border-bottom: none !important;
          // height: 41px !important;
          // height: 19.8px !important;
        }
      }
    }
  }
  .rbc-time-header.rbc-overflowing {
    border-right: 0 !important;
    padding: 0 !important;
  }
  .rbc-time-view-resources .rbc-header,
  .rbc-time-view-resources .rbc-day-bg {
    width: unset !important;
  }
  .rbc-time-header-content {
    // width: calc((100% - 100px) / 7) !important;
    max-width: unset !important;
    margin: 0 !important;
    border-left: 0.25px solid rgba(133, 133, 133, 0.4) !important;
    &:nth-child(2) {
      border-left: 0 !important;
    }
  }
  .rbc-day-slot.rbc-time-column {
    // width: calc((100% - 99px) / 7) !important;
    min-width: unset !important;
    max-width: unset !important;
  }
  .rbc-time-gutter {
    border-right: 1px solid #c5dcff !important;
  }
  .rbc-timeslot-group {
    max-height: unset !important;
    height: unset !important;
  }
  .calendarDay .rbc-day-slot {
    max-width: unset !important;
  }
  &.noGutter {
    .rbc-time-header-gutter,
    .rbc-time-gutter {
      display: none !important;
      z-index: 999;
    }
    .rbc-day-slot.rbc-time-column {
      // width: calc(100% / 7) !important;
      min-width: unset !important;
      max-width: unset !important;
    }
  }
  &.noHeader {
    .rbc-time-header {
      display: none !important;
      z-index: 999;
    }
    .rbc-time-gutter {
      border-top: 1px solid #cac0c0 !important;
      &:nth-child(1) {
        border-top: 0 !important;
      }
      background: $solitude !important;
      .rbc-timeslot-group {
        position: relative;
        border-bottom: 0 !important;
        border-left: none !important;
        &:nth-child(4n + 1) {
          .rbc-label {
            background: #f3f3f3;
          }
        }
        &:nth-child(4n + 2) {
          .rbc-label {
            background: #f3f3f3;
          }
        }
        &:nth-child(4n) {
          border-bottom: 1px solid #000 !important;
          .rbc-label {
            border-bottom: none !important;
            // height: 41px !important;
            height: 19.8px !important;
          }
        }
        &:nth-child(4n + 2)::after {
          content: '';
          position: absolute;
          bottom: -15px;
          left: 25%;
          transform: translate(-50%, 0%);
          color: $primary;
          font-weight: bold;
          font-size: 24px;
          z-index: 999;
        }
        &:nth-child(2)::after {
          content: '16';
          z-index: 999;
        }
        &:nth-child(6)::after {
          content: '17';
          z-index: 999;
        }
        &:nth-child(10)::after {
          content: '18';
          z-index: 999;
        }
        &:nth-child(14)::after {
          content: '19';
          z-index: 999;
        }
        &:nth-child(18)::after {
          content: '20';
          z-index: 999;
        }
        &:nth-child(22)::after {
          content: '21';
          z-index: 999;
        }
        &:nth-child(26)::after {
          content: '22';
          z-index: 999;
        }
        &:nth-child(30)::after {
          content: '23';
          z-index: 999;
        }
      }
    }
  }
}
